





































































































































import Vue from 'vue'
import {
  VegetationIssueResult,
  SubAssetResult,
  ManagementAreaResult
} from '../veg-common/apiTypes'
import VegeIssueFilterComponent from '../components/VegeIssueFilterComponent.vue'
import { RelationsRes } from '../veg-common/apiTypes/'
import VegeIssueViewTable from './VegeIssueView/VegeIssueViewTable.vue'
import VegeIssueInfoDialog from './VegeIssueView/VegeIssueInfoDialog.vue'
import VegeIssueViewMap from './VegeIssueView/VegeIssueViewMap.vue'
import { IsMobileDevice } from '../util/ParseUserAgent'

export default Vue.extend({
  components: {
    VegeIssueFilterComponent,
    VegeIssueInfoDialog,
    VegeIssueViewMap,
    VegeIssueViewTable
  },
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    },
    relations: {
      type: Object as () => RelationsRes,
      default: () => {}
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    },
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    }
  },
  data(): VegeIssueViewI {
    return {
      // default to closed for expansion panel. Only applicable on mobile
      tableExpansionPanel: false,
      filterExpansionPanel: false,
      wrapped: true,
      rowsAreSelected: false,

      tab: 0,
      pageSelect: 0,

      downloadDialog: false,
      deleteIssuesDialog: false,
      filteredDataForTable: []
    }
  },
  computed: {
    // buttonMessage() {
    //   if (this.isClientUser) {
    //     return 'Create New Issue'
    //   } else {
    //     return 'Add Follow Up Issue'
    //   }
    // },
    isMobileDevice(): boolean {
      return IsMobileDevice()
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    downloadButtonText(): string {
      return this.rowsAreSelected
        ? 'Download Selected Issues'
        : 'Download All Filtered Issues'
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    }
  },
  methods: {
    setRowsAreSelected(rowsAreSelected: boolean): void {
      this.rowsAreSelected = rowsAreSelected
    },
    openDownloadDialog() {
      this.downloadDialog = true
    },
    downloadKml(): void {
      //Default child component is VegeissueViewMap component,
      //so if user click the download selected issue button
      //$refs.vegeIssueTable is undefined
      //error message in console will complain can't call downloadKml method of undefined
      //without showing snack error message
      //@ts-ignore
      this.$refs.vegeIssueTable
        ? //@ts-ignore
          this.$refs.vegeIssueTable.downloadKml()
        : this.showError()
      this.downloadDialog = false
    },
    downloadCsv(): void {
      //@ts-ignore
      this.$refs.vegeIssueTable
        ? //@ts-ignore
          this.$refs.vegeIssueTable.downloadCsv()
        : this.showError()
      this.downloadDialog = false
    },
    deleteIssues(): void {
      //@ts-ignore
      this.$refs.vegeIssueTable
        ? //@ts-ignore
          this.$refs.vegeIssueTable.deleteSelectedIssues()
        : this.showError()
    },
    routeToAddNewIssue() {
      this.$router.push('/vegetation-issues/add')
    },
    setIssueData(filteredIssueData: VegetationIssueResult[]): void {
      this.filteredDataForTable = filteredIssueData
    },
    showError(): void {
      this.$typedStore.commit('setSnackbarParams', {
        type: 'error',
        msg: 'No issues selected!'
      })
    }
  },
  watch: {
    pageSelect(): void {
      if (
        this.$route.path === '/overview' ||
        this.$route.path === '/vegetation-issues'
      ) {
        let showMap: boolean = false
        if (this.pageSelect === 0) {
          showMap = true
        }
        this.$typedStore.commit('setMapView', showMap)
      }
    }
  },
  beforeCreate() {
    if (this.$route.query) {
      this.$typedStore.commit('setMapView', false)
    }
  },
  created(): void {
    this.filteredDataForTable = this.vegeIssueData
  },
  beforeMount(): void {
    if (this.$typedStore.state.showMap) {
      this.pageSelect = 0
    } else {
      this.pageSelect = 1
    }
  }
})

interface VegeIssueViewI {
  tableExpansionPanel: boolean
  filterExpansionPanel: boolean
  tab: number
  pageSelect: number
  wrapped: boolean
  rowsAreSelected: boolean

  downloadDialog: boolean
  deleteIssuesDialog: boolean
  filteredDataForTable: VegetationIssueResult[]
}
