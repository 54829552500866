











































import Vue from 'vue'
import MapComponent from './MapComponent.vue'
import { DrawablePolygon } from '../util/drawing'
import { IsMobileDevice } from '../util/ParseUserAgent'
import { SubAssetResult } from '../veg-common/apiTypes'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    }
  },
  data(): SeeAreasDialogI {
    return {
      mapDialog: false,
      mapIsLoaded: false, //place here for future
      drawingInProgress: false
    }
  },
  computed: {
    managementAreaPolygons(): DrawablePolygon[] {
      return this.$typedStore.getters.managementAreaPolygons
    }
  },
  methods: {
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.drawAreasOnMap()
    },
    async drawAreasOnMap(): Promise<void> {
      if (!this.mapIsLoaded) {
        return
      }

      this.drawingInProgress = true

      // @ts-ignore
      this.$refs.mapComp.removeAllPolygons()

      this.managementAreaPolygons.forEach((polygon) => {
        // https://stackoverflow.com/questions/57416991/
        // vue-typescript-how-to-avoid-ts-error-when-accessing-child-components-methods
        // Vue has issues typing refs, but accessing a child's method is recommended to use $refs
        // @ts-ignore
        this.$refs.mapComp.drawPolygon(polygon, true)
      })

      if (IsMobileDevice()) {
        //@ts-ignore
        this.$refs.mapComp.drawUserLocation(true)
      } else {
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      }

      this.drawingInProgress = false
    }
  },
  watch: {
    managementAreaPolygons() {
      this.drawAreasOnMap()
    }
  }
})

interface SeeAreasDialogI {
  mapDialog: boolean
  mapIsLoaded: boolean
  drawingInProgress: boolean
}
