










































import Vue from 'vue'
import MapComponent from './MapComponent.vue'
import { IsMobileDevice } from '../util/ParseUserAgent'
import midpoint from '@turf/midpoint'
import { Point } from 'geojson'
import { CulvertResult, CulvertInspectionResult } from '../veg-common/apiTypes'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    culverts: {
      type: Array as () => CulvertWithDistanceToUser[],
      default: (): CulvertWithDistanceToUser[] => []
    }
  },
  data(): SeeCulvertsDialogI {
    return {
      mapDialog: false,
      drawingInProgress: false,
      mapIsLoaded: false
    }
  },
  methods: {
    findMidpoints() {
      if (!this.mapIsLoaded) {
        return
      }
      //@ts-ignore
      this.$refs.mapComp.removeAllMarkers()
      this.drawingInProgress = true
      this.culverts.forEach((item) => {
        let mid = midpoint(
          item.culvert.inlet_location as Point,
          item.culvert.outlet_location as Point
        )
        // @ts-ignore
        this.$refs.mapComp.drawCulvertMarker(
          mid.geometry as Point,
          {
            clickable: true,
            showLabel: true
          },
          item.culvert,
          item.latestInspection
        )
      })
      if (IsMobileDevice()) {
        //@ts-ignore
        this.$refs.mapComp.drawUserLocation(true)
      } else {
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      }
      //@ts-ignore
      this.$refs.mapComp.markerCluster()
      this.drawingInProgress = false
    },
    // initializeMap is already emitting
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.findMidpoints()
    }
  }
})

interface SeeCulvertsDialogI {
  mapDialog: boolean
  mapIsLoaded: boolean
  drawingInProgress: boolean
}
interface CulvertWithDistanceToUser {
  culvert: CulvertResult
  latestInspection: CulvertInspectionResult | null
  distance: number
}
