






















































import Vue from 'vue'
import MapComponent from './MapComponent.vue'
import { DrawablePolygon, riskToColour } from '../util/drawing'
import { determineMaxRisk } from '../util/CalculateIssueRisk'
import { getListOfMostRecentFollowups } from '../util/IssueHelpers'
import _flatMap from 'lodash/flatMap'
import {
  ManagementAreaResult,
  VegetationIssueResult
} from '../veg-common/apiTypes'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    area: {
      type: Object as () => ManagementAreaResult | null
    }
  },
  data(): SeeIssueDialogI {
    return {
      maxRisk: null,
      mapDialog: false,
      mapIsLoaded: false,
      drawingInProgress: false,
      issueList: []
    }
  },
  computed: {
    managementAreaPolygons(): DrawablePolygon[] {
      return this.$typedStore.getters.managementAreaPolygons
    },
    setRiskColour(): string {
      if (this.area) {
        return riskToColour(this.maxRisk)
      } else {
        return ''
      }
    },
    maxRiskIssueNumber(): string {
      if (this.area) {
        let text = ''
        if (this.maxRisk === 1) {
          text = ' Low Risk'
        } else if (this.maxRisk === 5) {
          text = ' Medium Risk'
        } else if (this.maxRisk === 10) {
          text = ' High Risk'
        } else {
          text = ' No Risk'
        }
        if (this.area.issues && this.issueList.length > 0) {
          let maxRiskIssueNum = this.issueList.filter((issue) =>
            issue ? issue.risk_score === this.maxRisk : false
          )
          return maxRiskIssueNum.length + text
        } else {
          return 'No'
        }
      } else {
        return 'No'
      }
    }
  },
  methods: {
    mapLoaded(loadedSuccessfully: boolean): void {
      // This gets run after initializeMap emits "mapLoaded"
      // So we don't try to add markers before the map is ready
      this.mapIsLoaded = loadedSuccessfully
      if (loadedSuccessfully) this.drawPointsOnMap()
    },
    drawPointsOnMap(): void {
      this.drawingInProgress = true

      let foundPolygon = this.managementAreaPolygons.find((polygon) => {
        return polygon.area.id === (this.area ? this.area.id : null)
      })
      //list of points coming from asset view table
      if (this.area) {
        for (let idx = 0; idx < this.issueList.length; idx++) {
          let issue = this.issueList[idx]
          //only original issues shown
          if (issue.gps_point) {
            //@ts-ignore
            this.$refs.mapComp.drawIssueMarker(
              issue.gps_point,
              {
                clickable: true,
                showLabel: true
              },
              issue
            )
          }
        }
      }
      if (foundPolygon) {
        //@ts-ignore
        this.$refs.mapComp.drawPolygon(foundPolygon, true)
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnArea(foundPolygon.area.shape)
      } else {
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
      }
      this.drawingInProgress = false
    }
  },
  created() {
    if (this.area) {
      this.maxRisk = determineMaxRisk(this.area.issues)
      this.issueList = getListOfMostRecentFollowups(this.area.issues)
    }
  }
})

interface SeeIssueDialogI {
  mapDialog: boolean
  mapIsLoaded: boolean
  drawingInProgress: boolean
  maxRisk: number | null
  issueList: VegetationIssueResult[]
}
