export default {
  token: null,
  latestPosition: null,
  zoom: null,
  position: null,
  selectedAssetOverviewYear: null,
  showMap: true,
  showLsdGrid: false,
  snackbarParams: null,
  version: process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : '',
  appliedFilters: {
    managementAreaSelectedValues: [],
    dates: [],
    riskScoreSelectedValues: [],
    photoStatusSelectedValues: [],
    issueTypeSelectedValues: [],
    actionTakenSelectedValues: [],
    physiologicalStageSelectedValues: [],
    infraVegetationTypesSelectedValues: [],
    weedVegetationTypesSelectedValues: [],
    distributionSelectedValues: [],
    proximitySelectedValues: [],
    originalIssueSelectedValues: [],
    selectedInitiators: [],
    selectedWeedSpecies: [],
    selectedActionsRequired: [],
    issueIdSearch: null,
    subAssetSelectedValues: [],
    commentSearch: '',
    selectedAssignedUsers: [],
    selectedServiceCompanies: [],
    interactiveSelectedValue: null
  },
  appliedCulvertInspectionFilters: {
    culvertSelectedValues: [],
    inspectionDateRange: [],
    nextInspectionDateRange: [],
    riskScoreSelectedValues: [],
    culvertStatusSelectedValues: [],
    photoStatusSelectedValues: [],
    selectedActionsTaken: [],
    selectedInitiators: [],
    selectedActionsRequired: [],
    commentSearch: ''
  },
  appliedCulvertFilters: {
    siteIdSelectedValues: [],
    roadNameSearch: '',
    riskScoreSelectedValues: [],
    culvertStatusSelectedValues: [],
    fishHabitatSelectedValues: [],
    markersSelectedValues: [],
    diameterRange: [-1, 150],
    lengthRange: [-1, 150]
  },
  appData: {
    company: null,
    alerts: {},
    assets: {},
    selectedAssetId: null,
    users: {},
    relatedThirdPartyCompanies: {},
    relations: {
      takenActions: [],
      requiredActions: [],
      distributions: [],
      issueTypes: [],
      physiologicalStages: [],
      proximities: [],
      species: [],
      weedVegetationTypes: [],
      infraVegetationTypes: [],
      culvertStatuses: [],
      culvertTakenActions: [],
      culvertRequiredActions: []
    }
  },
  assetData: {
    areas: {},
    assetAssociatedSpecies: {},
    inspections: {},
    issues: {},
    subAssets: {},
    culverts: {},
    culvertInspections: {}
  },
  unsyncedIssues: [],
  unsyncedPhotos: [],
  unsyncedCulvertInspections: [],
  unsyncedCulverts: [],
  dataSyncing: false,
  // starting with <loggedIntoVegeLogic: true> just until we have user authentication.
  loggedIntoVegeLogic: true,
  loggedIntoCulvertLogic: false,
  dataLoadedFromCache: false
}
