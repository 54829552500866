























































































import Vue from 'vue'
import MapComponent from './MapComponent.vue'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'
import { Position } from '@capacitor/geolocation'

import { IsMobileDevice } from '../util/ParseUserAgent'
import { DrawablePolygon } from '../util/drawing'
import { ManagementAreaResult } from '../veg-common/apiTypes'

import { Point } from 'geojson'

export default Vue.extend({
  components: {
    MapComponent
  },
  props: {
    selectedManagementArea: {
      type: Number as () => number
    },
    multipleSelectedManagementArea: {
      type: Array as () => number[]
    },
    gpsPoint: {
      type: Object as () => Point
    },
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    },
    addSubAssetCheck: {
      type: Boolean as () => Boolean,
      default: (): Boolean => false
    }
  },
  data(): GpsPointCompI {
    return {
      mapLoadedComplete: false,
      gps_point: null,
      gpsPointMapDialog: false,
      chooseInputTypeDialog: false
    }
  },
  watch: {
    mapLoadedComplete(): void {
      if (this.mapLoadedComplete && this.gps_point) {
        //@ts-ignore
        this.$refs.mapComp.drawIssueMarker(this.gps_point, {
          clickable: false,
          showLabel: false
        })
      }
    },
    multipleSelectedManagementArea() {
      if (this.addSubAssetCheck) {
        this.drawMultipleAreas()
      } else {
        this.drawSelectedArea()
      }
    }
  },
  computed: {
    managementAreaPolygons(): DrawablePolygon[] {
      return this.$typedStore.getters.managementAreaPolygons
    },
    gpsPointButtonText(): string {
      if (this.gps_point !== null) {
        return 'Point Has Been Set'
      }
      return 'Drop Point On Map'
    },
    gpsPointButtonColor(): VuetifyThemeItem | undefined {
      if (this.gps_point !== null) {
        return this.$vuetify.theme.themes.dark.secondary
      } else {
        return this.$vuetify.theme.themes.dark.primary //default button color
      }
    },
    watchPosition(): Position | null {
      return this.$typedStore.state.latestPosition
    },
    mapHeight(): string {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'height:70vh; width:75vw;'
      } else {
        return 'height:75vh; width:75vw;'
      }
    }
  },
  methods: {
    drawMultipleAreas(): void {
      if (this.mapLoadedComplete && this.managementAreas !== null) {
        //@ts-ignore
        this.$refs.mapComp.removeAllPolygons()
        this.managementAreaPolygons.forEach((polygon) => {
          //@ts-ignore
          this.$refs.mapComp.drawPolygon(polygon, false)
        })
      }
    },
    drawSelectedArea(): void {
      if (this.mapLoadedComplete && this.selectedManagementArea !== null) {
        let foundPolygon = this.managementAreaPolygons.find((polygon) => {
          return polygon.area.id === this.selectedManagementArea
        })
        //@ts-ignore
        this.$refs.mapComp.removeAllPolygons()
        //@ts-ignore
        this.$refs.mapComp.drawPolygon(foundPolygon, false)
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnArea(foundPolygon.area.shape)
      }
    },
    async getGpsPoint(): Promise<void> {
      if (IsMobileDevice()) {
        this.chooseInputTypeDialog = true
      } else {
        this.manuallyPlacePoint()
      }
    },
    manuallyPlacePoint(): void {
      this.gpsPointMapDialog = true
    },
    gpsPlacePoint(): void {
      if (this.watchPosition) {
        this.gps_point = {
          type: 'Point',
          coordinates: [
            this.watchPosition.coords.longitude,
            this.watchPosition.coords.latitude
          ]
        }
        this.$emit('savePoint', this.gps_point)
        this.$emit('setGpsError', false)
      }
    },
    mapLoaded(loadedSuccessfully: boolean): void {
      if (loadedSuccessfully) {
        //@ts-ignore
        this.$refs.mapComp.createMapClickListener()
        //@ts-ignore
        this.$refs.mapComp.zoomMapOnAsset()
        this.mapLoadedComplete = true
        if (this.addSubAssetCheck) {
          this.drawMultipleAreas()
        } else {
          this.drawSelectedArea()
        }
      }
    },
    mapClicked(position: Point): void {
      this.gps_point = position
    },
    cancelManualGpsPoint(): void {
      this.gps_point = null
      //@ts-ignore
      this.$refs.mapComp.removeAllMarkers()
      this.$emit('savePoint', this.gps_point)
    },
    saveAndCloseDialog(): void {
      this.$emit('savePoint', this.gps_point)
      this.gpsPointMapDialog = false
      this.$emit('setGpsError', false)
    }
  },
  mounted() {
    if (this.gpsPoint) {
      this.gps_point = this.gpsPoint
    }
  }
})

interface GpsPointCompI {
  mapLoadedComplete: boolean
  gps_point: Point | null
  gpsPointMapDialog: boolean
  chooseInputTypeDialog: boolean
}
