import { Position } from '@capacitor/geolocation'

import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'

import Vue from 'vue'
import Vuex from 'vuex-typescript-interface'
import * as Entities from '../sqlite/entities'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

import { DrawablePolygon } from '../util/drawing'
import { Photo } from '../util/imageProcessing'
import { JwtContent } from '../veg-common'
import {
  GetAppResponse,
  GetAssetResponse,
  RelationsRes,
  LoginResponse,
  ManagementAreaResult,
  VegetationIssueResult,
  VegetationIssueParams,
  SubAssetResult,
  AlertResponse,
  CulvertResult,
  CulvertInspectionResult,
  CulvertInspectionParams,
  NewCulvertParams,
  GetThirdPartyCompanyRelationsResponse
} from '../veg-common/apiTypes'
import {
  AssetObject,
  CompanyObject,
  UserObject,
  VegSpeciesObject
} from '../veg-common/apiTypes/newResultTypes'
import {
  AppData,
  AssetData,
  AppliedFiltersData,
  AppliedCulvertInspectionFiltersData,
  AppliedCulvertFiltersData,
  SnackbarParams
} from './types'

Vue.use(Vuex)
export interface IStore {
  // State (are non-function properties not marked readonly)
  token: string | null
  latestPosition: Position | null
  zoom: number | null
  position: google.maps.LatLngLiteral | null
  selectedAssetOverviewYear: number | null
  showMap: boolean | null
  showLsdGrid: boolean
  snackbarParams: SnackbarParams | null
  version: string
  appliedFilters: AppliedFiltersData
  appliedCulvertInspectionFilters: AppliedCulvertInspectionFiltersData
  appliedCulvertFilters: AppliedCulvertFiltersData
  appData: AppData
  assetData: AssetData
  unsyncedIssues: Entities.VegetationIssueParamsEntity[]
  unsyncedPhotos: Entities.CreatePhotoRequestEntity[]
  unsyncedCulverts: Entities.NewCulvertParamsEntity[]
  unsyncedCulvertInspections: Entities.CulvertInspectionParamsEntity[]
  dataSyncing: boolean
  loggedIntoVegeLogic: boolean
  loggedIntoCulvertLogic: boolean
  dataLoadedFromCache: boolean

  // Getters (are non-function properties marked readonly)

  //Misc Getters
  readonly apiUrl: string
  readonly basePhotoUrl: string
  readonly company: CompanyObject | null
  readonly getSnackbarParams: SnackbarParams | null
  readonly hasUnsyncedData: boolean
  readonly isAdminRole: boolean
  readonly isLoggedIn: boolean
  readonly isSuper: boolean
  readonly jwtContent: JwtContent | null
  readonly managementAreaPolygons: DrawablePolygon[]
  readonly selectedAsset: AssetObject | null
  readonly tokenValid: boolean
  readonly user: UserObject | null

  //Raw Data getters
  readonly alerts: AlertResponse[]
  readonly assetAssociatedSpecies: VegSpeciesObject[]
  readonly assetsArray: AssetObject[]
  readonly culverts: CulvertResult[]
  readonly culvertInspections: CulvertInspectionResult[]
  readonly managementAreas: ManagementAreaResult[]
  readonly relatedThirdPartyCompanies: GetThirdPartyCompanyRelationsResponse[]
  readonly relations: RelationsRes
  readonly subAssets: SubAssetResult[]
  readonly usersArray: UserObject[]
  readonly vegetationIssues: VegetationIssueResult[]

  //Relations mapping getters
  readonly actionsMap: Record<number, string>
  readonly culvertActionsMap: Record<number, string>
  readonly culvertlogicStatus: boolean
  readonly culvertStatusMap: Record<number, string>
  readonly distributionsMap: Record<number, string>
  readonly issueTypesMap: Record<number, string>
  readonly physiologicalStagesMap: Record<number, string>
  readonly proximitiesMap: Record<number, string>
  readonly speciesMap: Record<number, string>
  readonly vegelogicStatus: boolean
  readonly vegetationTypesMap: Record<number, string>

  // Mutations (functions return void and optionally accept payload)
  clearAssetData(): void
  clearFilters(): void
  resetSnackbar(): void
  setLoggedIntoStatus(loggedInStatuses: {
    loggedIntoVegeLogic: boolean
    loggedIntoCulvertLogic: boolean
  }): void
  setAppData(appData: {
    alerts: Entities.AlertResponseEntity[]
    assets: Entities.AssetResultEntity[]
    relatedThirdPartyCompanies: Entities.ThirdPartyEntity[]
    users: Entities.UsersEntity[]
    extraAppData?: Entities.ExtraAppDataEntity
    relations?: Entities.RelationsEntity
    vegIssueFilters?: Entities.VegeIssueAppliedFilters
    culvertInspectionFilters?: Entities.AppliedCulvertInspectionFiltersDataEntity
    culvertFilters?: Entities.AppliedCulvertFiltersDataEntity
    loggedInStoreState?: Entities.AppLoggedInStoreStateEntity
    storeState?: Entities.StoreStateEntity
  }): void
  setAssetData(assetData: {
    areas: Entities.ManagementAreasEntity[]
    issues: Entities.VegetationIssuesResultEntity[]
    subAssets: Entities.SubAssetResultEntity[]
    culverts: Entities.CulvertResultEntity[]
    culvertInspections: Entities.CulvertInspectionResultEntity[]
    assetSpeciesList: Entities.AssetSpeciesEntity[]
  }): void
  logout(): void
  setMapView(showMap?: boolean | null): void
  setPosition(position: google.maps.LatLngLiteral | null): void
  setSelectedAssetOverviewYear(year: number | null): void
  setSnackbarParams(snackbarParams: SnackbarParams): void
  setStoreState(storeState: Entities.StoreStateEntity): void
  setVersion(version: string): void
  setZoom(zoom?: number | null): void

  // Actions (functions return Promise and optionally accept payload)
  doLogin(info: LoginResponse): Promise<void>
  doLogout(): Promise<void>

  // Actions that set user type data, that isn't authentication related
  checkVersion(version: string): Promise<void>
  doClearFilters(): Promise<void>
  getUpdateTime(item: 'app' | number): Promise<string | null>
  loadCache(): Promise<void>
  loadToken(): Promise<void>
  setAlerts(alerts: AlertResponse[]): Promise<void>
  setAppliedFilters(filtersData: any): Promise<void>
  setAppliedCulvertInspectionFilters(filtersData: any): Promise<void>
  setAppliedCulvertFilters(filtersData: any): Promise<void>
  setLoggedIntoTool(loggedIntoStatuses: {
    loggedIntoVegeLogic: boolean
    loggedIntoCulvertLogic: boolean
  }): Promise<void>
  setShowLsdGrid(setLsdGrid: boolean): Promise<void>
  setSelectedAsset(id: number): Promise<void>
  setRelations(relations: RelationsRes): Promise<void>
  setThirdPartyCompanies(
    thirdPartyCompanies: GetThirdPartyCompanyRelationsResponse[]
  ): Promise<void>
  setToken(token: string): Promise<void>
  setUpdateTime(payload: {
    item: 'app' | number
    time: string | null
  }): Promise<void>
  updateAppData(data: GetAppResponse): Promise<void>
  updateAssetData(payload: {
    assetId: number
    data: GetAssetResponse
    firstTimeSetup: boolean
  }): Promise<void>

  //Offline saving and syncing data actions
  runSync(): Promise<void>
  saveCulvert(payload: { params: NewCulvertParams }): Promise<void>
  saveCulvertInspection(payload: {
    params: CulvertInspectionParams
    photos: Photo[]
  }): Promise<void>
  saveIssue(payload: {
    params: VegetationIssueParams
    photos: Photo[]
  }): Promise<void>
}

export default new Vuex.Store<IStore>({
  state,
  getters,
  mutations,
  actions
})
