


















































































































































































































































/* globals google */
import Vue from 'vue'
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier'
import {
  CulvertResult,
  CulvertInspectionResult,
  ManagementAreaResult,
  VegetationIssueResult,
  SubAssetResult
} from '../veg-common/apiTypes'
import { Point, LineString, Polygon } from 'geojson'
import { Feature } from '@turf/helpers'
import pointOnFeature from '@turf/point-on-feature'
import centerOfMass from '@turf/center-of-mass'
import midpoint from '@turf/midpoint'
import { positionToMercator } from '@terraformer/spatial'
import { DrawablePolygon, riskToColour } from '../util/drawing'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { format as dfnFormat, parseISO as dfnParseISO, getYear } from 'date-fns'
import { Position } from '@capacitor/geolocation'
import { IsAppleMobileDevice } from '../util/ParseUserAgent'

import IssueInformationCard from './IssueInformationCard.vue'
import AreaInformationDialog from './AreaInformationDialog.vue'
import CulvertInfoCard from '../views/CulvertLogic/CulvertInfoCard.vue'

export default Vue.extend({
  components: {
    IssueInformationCard,
    AreaInformationDialog,
    CulvertInfoCard
  },
  props: {
    gestureHandling: {
      type: String as () => google.maps.GestureHandlingOptions
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    },
    hideDefaultView: {
      type: Boolean as () => Boolean,
      default: (): Boolean => false
    },
    overlayOverride: {
      type: Boolean as () => Boolean | null,
      default: null
    },
    //boolean used to help determine a good height for the custom controls.
    //if the page has buttons or filters, this should stay false to accommodate for the button heights
    isMapOnlyPage: {
      type: Boolean as () => Boolean,
      default: false
    }
  },
  data(): MapComponentI {
    return {
      // default position is roughly "Alberta"
      defaultPosition: { lat: 53.9333, lng: -116.5765 },

      map: null,
      markerSpiderfier: null,

      boundsChanged: false,
      zoom: 8,

      disabledIssueMarkerIcon: {
        url: require('../../static/map/disabledIssueMarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      defaultMarkerIcon: {
        url: require('../../static/map/defaultIssueMarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      noRiskMarkerIcon: {
        url: require('../../static/map/noRiskMarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      lowRiskMarkerIcon: {
        url: require('../../static/map/lowRiskMarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      medRiskMarkerIcon: {
        url: require('../../static/map/medRiskMarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      highRiskMarkerIcon: {
        url: require('../../static/map/highRiskMarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      userMarkerIcon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        anchor: new google.maps.Point(0, 0),
        scale: 5,
        strokeColor: '#FFFFFF',
        fillColor: this.$vuetify.theme.themes.light.userMarkerColor
          ? this.$vuetify.theme.themes.light.userMarkerColor.toString()
          : undefined,
        fillOpacity: 1,
        strokeWeight: 1.5
      },
      noHeadingUserMarkerIcon: {
        path: google.maps.SymbolPath.CIRCLE,
        anchor: new google.maps.Point(0, 0),
        scale: 5,
        strokeColor: '#FFFFFF',
        fillColor: this.$vuetify.theme.themes.light.userMarkerColor
          ? this.$vuetify.theme.themes.light.userMarkerColor.toString()
          : undefined,
        fillOpacity: 1,
        strokeWeight: 1.5
      },
      clickedMapMarkerIcon: {
        url: require('../../static/map/clickmapmarker.svg'),
        scaledSize: new google.maps.Size(25, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 27)
      },
      polygonList: [],
      polygonLabels: [],
      linePath: null, //current assumption is we will only want one path drawn at a time, we can changes this to an array if we want more then one
      issueMarkerList: [],

      userMarker: null,
      mapClickedMarker: null,
      hideMarkers: false,
      manuallyDrawnPath: null,

      areaDialog: false,
      selectedAreaForDisplay: null,
      markerDialog: false,
      culvertMarkerDialog: false,
      selectedIssueForDisplay: null,
      selectedCulvertForDisplay: null,
      selectedInspectionForDisplay: null,

      userMarkerOnMap: false,

      markerClusterObj: null,
      issueList: [],

      selectedSubAsset: null,

      zoomPositionChanged: false,

      selectedYear: null,
      uniqueYearList: [],
      yearSelectMenu: false,
      drawingManager: null
    }
  },
  computed: {
    title(): string {
      if (
        this.selectedIssueForDisplay &&
        this.selectedIssueForDisplay.isInteractive
      ) {
        if (this.selectedIssueForDisplay.id > 0) {
          return `VegLogic Id ${this.selectedIssueForDisplay.id}`
        } else {
          return 'Issue Done Offline, No Id Set Yet'
        }
      } else {
        return 'Issue Not Assigned For Work'
      }
    },
    currentPosition(): Position | null {
      return this.$typedStore.state.latestPosition
    },
    currentPositionAsPoint(): Point | null {
      return this.currentPosition
        ? {
            type: 'Point',
            coordinates: [
              this.currentPosition.coords.longitude,
              this.currentPosition.coords.latitude
            ]
          }
        : null
    },
    currentPositionAsGoogle(): google.maps.LatLngLiteral | null {
      return this.currentPosition
        ? {
            lat: this.currentPosition.coords.latitude,
            lng: this.currentPosition.coords.longitude
          }
        : null
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.sm
    },
    isSmAndDown(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    riskText() {
      let textRisk: string = ''
      if (this.selectedIssueForDisplay) {
        switch (this.selectedIssueForDisplay.risk_score) {
          case 0:
            textRisk = 'No Risk'
            break
          case 1:
            textRisk = 'Low Risk'
            break
          case 5:
            textRisk = 'Medium Risk'
            break
          case 10:
            textRisk = 'High Risk'
            break
          default:
            textRisk = 'Risk Unknown'
            break
        }
        return textRisk
      } else {
        return ''
      }
    },
    culvertRiskText() {
      let textRisk: string = ''
      if (this.selectedInspectionForDisplay) {
        switch (this.selectedInspectionForDisplay.risk_score) {
          case 0:
            textRisk = 'No Risk'
            break
          case 1:
            textRisk = 'Low Risk'
            break
          case 5:
            textRisk = 'Medium Risk'
            break
          case 10:
            textRisk = 'High Risk'
            break
          default:
            textRisk = 'Risk Unknown'
            break
        }
        return textRisk
      } else {
        return 'Awaiting'
      }
    },
    managementAreaPolygons(): DrawablePolygon[] {
      return this.$typedStore.getters.managementAreaPolygons
    }
  },
  watch: {
    hideMarkers(): void {
      if (this.hideMarkers) {
        this.issueMarkerList.forEach((marker) => {
          marker.setVisible(false)
        })
        if (this.userMarker) {
          this.userMarker.setVisible(false)
        }
      } else {
        this.issueMarkerList.forEach((marker) => {
          marker.setVisible(true)
        })
        if (this.userMarker) {
          this.userMarker.setVisible(true)
        }
      }
    },
    selectedSubAsset(): void {
      if (this.selectedSubAsset) {
        this.zoomMapAtPoint(
          this.selectedSubAsset.latitude,
          this.selectedSubAsset.longitude,
          13
        )
      }
      this.zoomPositionChanged = false
    },
    currentPosition(): void {
      if (this.currentPosition !== null && this.map) {
        this.drawUserLocation(false)
      }
    },
    markerDialog(): void {
      //if dialog clicked off of or close button pressed markerDialog will be false
      //issue needs to be nulled in order to destroy the component (with the v-if)
      // so that the map can be reinitialized in the IssueInformationCard component to show the correct issue on that map
      if (this.markerDialog === false) {
        this.selectedIssueForDisplay = null
      }
    },
    culvertMarkerDialog(): void {
      //if dialog clicked off of or close button pressed culvertMarkerDialog will be false
      //issue needs to be nulled in order to destroy the component (with the v-if)
      // so that the map can be reinitialized in the IssueInformationCard component to show the correct issue on that map
      if (this.culvertMarkerDialog === false) {
        this.selectedInspectionForDisplay = null
      }
    }
  },
  methods: {
    closeYearSelectMenu() {
      this.yearSelectMenu = false
      // @ts-ignore
      this.$refs.yearSelect.blur()
    },
    setSelectedYear(): void {
      this.$emit('setSelectedYear', this.selectedYear)
    },
    initializeMap(): void {
      let elementRef = this.$refs.map as HTMLElement

      let newMap = new google.maps.Map(elementRef, {
        zoom: this.zoom,
        center: this.defaultPosition,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        gestureHandling: this.gestureHandling ? this.gestureHandling : 'auto', //auto is maps default, our default as a result
        mapTypeControl: false,
        streetViewControl: false,
        tilt: 0,
        rotateControl: false,
        scaleControl: true
      })
      newMap.addListener('bounds_changed', () => {
        setTimeout(() => {
          this.boundsChanged = false
        }, 1000)

        //@ts-ignore
        if (!this.boundsChanged && newMap.getZoom() > 10) {
          this.boundsChanged = true
          this.$emit('boundsChanged', newMap.getBounds())
        }
      })

      this.createSpiderfier(newMap)
      google.maps.event.addListener(newMap, 'zoom_changed', (event) => {
        this.zoom = newMap.getZoom()
        //@ts-ignore
        if (this.zoom !== 13) {
          if (!this.areaDialog) {
            this.$typedStore.commit('setZoom', this.zoom)
          }
          this.zoomPositionChanged = true
        }
        //@ts-ignore
        if (this.zoom >= 16) {
          this.polygonLabels.forEach((marker) => {
            marker.setVisible(true)
          })
        } else {
          this.polygonLabels.forEach((marker) => {
            marker.setVisible(false)
          })
        }
      })

      google.maps.event.addListener(newMap, 'center_changed', (event) => {
        if (
          this.$typedStore.getters.selectedAsset &&
          //@ts-ignore
          (newMap.getCenter().lat() !==
            this.$typedStore.getters.selectedAsset.latitude ||
            //@ts-ignore
            newMap.getCenter().lng() !==
              this.$typedStore.getters.selectedAsset.longitude)
        ) {
          this.$typedStore.commit('setPosition', {
            //@ts-ignore
            lat: newMap.getCenter().lat(),
            //@ts-ignore
            lng: newMap.getCenter().lng()
          })
          this.zoomPositionChanged = true
        }
      })
      this.map = newMap

      if (this.overlayOverride === null) {
        if (this.$typedStore.state.showLsdGrid) {
          this.setOverlay()
        }
      } else {
        if (this.overlayOverride) {
          this.setOverlay()
        }
      }

      // Catch this emit in the parent, then run a function to initialize any extra map features
      // Like adding markers or trips or whatever else. this should always be the last line of this function
      this.$emit('mapLoaded', true)

      this.zoomMapOnAsset()
    },

    createSpiderfier(map: google.maps.Map): void {
      this.markerSpiderfier = new OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
        nearbyDistance: 30
      })
    },
    setOverlay() {
      // Set the LSDs grid overlay on the map

      let app = this
      if (!app.map) return

      var SLPLayer = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          if (!app.map) return null

          let proj = app.map.getProjection()
          if (!proj) return null

          let zfactor = Math.pow(2, zoom)
          // get Long Lat coordinates
          let top = proj.fromPointToLatLng(
            new google.maps.Point(
              (coord.x * 256) / zfactor,
              (coord.y * 256) / zfactor
            )
          )
          let bot = proj.fromPointToLatLng(
            new google.maps.Point(
              ((coord.x + 1) * 256) / zfactor,
              ((coord.y + 1) * 256) / zfactor
            )
          )

          let topMercator = positionToMercator([top.lng(), top.lat()])
          let botMercator = positionToMercator([bot.lng(), bot.lat()])

          //create the Bounding box string
          let bbox =
            topMercator[0] +
            ',' +
            botMercator[1] +
            ',' +
            botMercator[0] +
            ',' +
            topMercator[1]

          //base WMS URL
          let url = 'https://webmap.enterratech.com/maps/ats/wms?'
          url += 'LAYERS=' + 'ats' //WMS layers
          url += '&FORMAT=image/png' //WMS format
          url += '&SERVICE=WMS' //WMS service
          url += '&VERSION=1.1.1' //WMS version
          url += '&REQUEST=GetMap' //WMS operation
          url += '&SRS=EPSG:3857' //set WGS84
          url += '&BBOX=' + bbox // set bounding box
          url += '&WIDTH=256' //tile size in google
          url += '&HEIGHT=256'
          url += '&TRANSPARENT=true'
          return url // return URL for the tile
        },
        tileSize: new google.maps.Size(256, 256)
      })
      app.map.overlayMapTypes.push(SLPLayer)
    },
    checkPolygonYear() {
      this.managementAreaPolygons.forEach((polygon) => {
        if (polygon.area.issues.length) {
          let vegIssue = polygon.area.issues
          vegIssue.forEach((issue) => {
            let date = getYear(new Date(issue.inspection_date))
            if (!this.uniqueYearList.includes(date)) {
              this.uniqueYearList.push(date)
            }
          })
        }
      })
      this.uniqueYearList.sort((a: number, b: number) => b - a)
    },
    createMapClickListener(): void {
      if (this.map) {
        google.maps.event.addListener(this.map, 'click', (event) => {
          if (this.mapClickedMarker !== null) {
            this.mapClickedMarker.setMap(null)
          }
          let geojsonPoint: Point = {
            type: 'Point',
            coordinates: [event.latLng.lng(), event.latLng.lat()]
          }
          this.mapClickedMarker = this.drawMarker(
            geojsonPoint,
            this.clickedMapMarkerIcon
          )
          this.$emit('mapClicked', geojsonPoint)
        })
      }
    },
    createDrawingManager(): void {
      if (this.map) {
        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            editable: true,
            geodesic: true,
            strokeColor: `${this.$vuetify.theme.currentTheme.complementaryRed}`,
            strokeOpacity: 1.0,
            strokeWeight: 3
          }
        })
        google.maps.event.addListener(
          this.drawingManager,
          'polygoncomplete',
          (polygon) => {
            if (this.manuallyDrawnPath) {
              google.maps.event.clearInstanceListeners(this.manuallyDrawnPath)
              this.manuallyDrawnPath.setMap(null)
            }
            this.manuallyDrawnPath = polygon
            let lineBounds = polygon.getPath()
            this.emitLineStringFromDrawnPolygon(lineBounds)
            this.assignListenersToLine(lineBounds)
          }
        )
        this.drawingManager.setMap(this.map)
      }
    },
    assignListenersToLine(line: google.maps.MVCArray<google.maps.LatLng>) {
      //move existing vertex
      google.maps.event.addListener(line, 'set_at', () => {
        if (this.manuallyDrawnPath)
          this.emitLineStringFromDrawnPolygon(this.manuallyDrawnPath.getPath())
      })
      //drag from middle of vertices to make a new vertex
      google.maps.event.addListener(line, 'insert_at', () => {
        if (this.manuallyDrawnPath)
          this.emitLineStringFromDrawnPolygon(this.manuallyDrawnPath.getPath())
      })
    },
    emitLineStringFromDrawnPolygon(
      polygonBoundaries: google.maps.MVCArray<google.maps.LatLng>
    ) {
      let linestring: LineString = {
        type: 'LineString',
        coordinates: []
      }
      polygonBoundaries.forEach((item, index) => {
        linestring.coordinates.push([item.lng(), item.lat()])
      })
      //close the linestring so it better matches what the user input
      linestring.coordinates.push([
        polygonBoundaries.getAt(0).lng(),
        polygonBoundaries.getAt(0).lat()
      ])
      this.$emit('lineFinished', linestring)
    },
    removeDrawnPath(): void {
      if (this.drawingManager) {
        this.drawingManager.setDrawingMode(
          google.maps.drawing.OverlayType.POLYGON
        )
      }
      if (this.manuallyDrawnPath) {
        this.manuallyDrawnPath.setMap(null)
      }
    },
    drawPolygon(polygon: DrawablePolygon, clickable: boolean): void {
      let app = this

      let newPolygon = new google.maps.Polygon({
        paths: polygon.coordinates,
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: polygon.colour,
        fillOpacity: polygon.opacity,
        clickable: clickable
      })
      if (clickable) {
        newPolygon.addListener('click', function () {
          app.areaDialog = true
          app.selectedAreaForDisplay = polygon.area
        })
      }

      let labelPos: Feature<Point> = pointOnFeature(
        polygon.area.shape as Polygon
      )

      if (labelPos.geometry !== null) {
        let marker = new google.maps.Marker({
          position: {
            lat: labelPos.geometry.coordinates[1],
            lng: labelPos.geometry.coordinates[0]
          },
          visible: false,
          icon: {
            // Image label should be made base64 in the api entity, setting the url to that base64 string will give us our svg label
            url: polygon.area.imageLabel
          }
        })
        marker.setMap(app.map)
        app.polygonLabels.push(marker)
      }

      app.polygonList.push(newPolygon)
      newPolygon.setMap(app.map)
    },
    removeAllPolygons(): void {
      for (let i = 0; i < this.polygonList.length; i++) {
        this.polygonList[i].setMap(null)
      }
      this.polygonList = []

      for (let i = 0; i < this.polygonLabels.length; i++) {
        this.polygonLabels[i].setMap(null)
      }
      this.polygonLabels = []
    },
    drawPath(path: LineString): void {
      let app = this

      let processedCoordinateList: google.maps.LatLngLiteral[] = []

      path.coordinates.forEach((coords) => {
        let lng = coords[0]
        let lat = coords[1]
        //geojson comes back in long, lat. gmaps expects lat, long
        processedCoordinateList.push({ lat, lng })
      })

      let newLine = new google.maps.Polyline({
        path: processedCoordinateList,
        geodesic: true,
        strokeColor: `${app.$vuetify.theme.currentTheme.complementaryRed}`,
        strokeOpacity: 1.0,
        strokeWeight: 3
      })
      app.linePath = newLine
      app.linePath.setMap(app.map)
    },
    // Make sure 'path' parameter has inlet before outlet to ensure arrows are drawn with the correct direction of flow.
    drawPathWithArrow(path: LineString): void {
      let app = this
      this.removePath()
      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        fillOpacity: 1.0
      }

      let processedCoordinateList: google.maps.LatLngLiteral[] = []
      path.coordinates.forEach((coords) => {
        let lng = coords[0]
        let lat = coords[1]
        //geojson comes back in long, lat. gmaps expects lat, long
        processedCoordinateList.push({ lat, lng })
      })

      let newLine = new google.maps.Polyline({
        path: processedCoordinateList,
        geodesic: true,
        icons: [
          {
            icon: lineSymbol,
            offset: '35%',
            repeat: '40%'
          }
        ],
        strokeColor: `${app.$vuetify.theme.currentTheme.complementaryRed}`,
        strokeOpacity: 1.0,
        strokeWeight: 3
      })
      app.linePath = newLine
      app.linePath.setMap(app.map)
    },

    drawEditablePath(path: LineString): void {
      let app = this

      let processedCoordinateList: google.maps.LatLngLiteral[] = []

      path.coordinates.forEach((coords) => {
        let lng = coords[0]
        let lat = coords[1]
        //geojson comes back in long, lat. gmaps expects lat, long
        processedCoordinateList.push({ lat, lng })
      })

      //when doing the edit the first and last point overlap so there are two points to drag to make the edit to that point,
      //just remove the last point if it matches the first one to avoid that awkward experience
      if (
        processedCoordinateList[0].lat ===
          processedCoordinateList[processedCoordinateList.length - 1].lat &&
        processedCoordinateList[0].lng ===
          processedCoordinateList[processedCoordinateList.length - 1].lng
      ) {
        processedCoordinateList.pop()
      }

      let editablePolygon = new google.maps.Polygon({
        paths: processedCoordinateList,
        geodesic: true,
        strokeColor: `${app.$vuetify.theme.currentTheme.complementaryRed}`,
        strokeOpacity: 1.0,
        strokeWeight: 3,
        editable: true
      })
      app.manuallyDrawnPath = editablePolygon
      app.manuallyDrawnPath.setMap(app.map)
      this.assignListenersToLine(editablePolygon.getPath())
    },
    removePath(): void {
      let app = this
      if (app.linePath !== undefined && app.linePath !== null) {
        app.linePath.setMap(null)
        app.linePath = null
      }
    },
    drawIssueMarker(
      point: Point,
      options: drawMarkerOptionsI,
      issue?: VegetationIssueResult
    ): void {
      let app = this
      let newMarker: google.maps.Marker

      if (!issue) {
        newMarker = app.drawMarker(point, app.defaultMarkerIcon)
      } else {
        let icon =
          issue.isInteractive === false
            ? app.disabledIssueMarkerIcon
            : issue.risk_score === null
            ? app.defaultMarkerIcon
            : issue.risk_score === 0
            ? app.noRiskMarkerIcon
            : issue.risk_score < 3
            ? app.lowRiskMarkerIcon
            : issue.risk_score < 7
            ? app.medRiskMarkerIcon
            : app.highRiskMarkerIcon

        newMarker = app.drawMarker(point, icon)
      }
      app.issueMarkerList.push(newMarker)
      if (issue) {
        if (options.clickable && this.markerSpiderfier) {
          google.maps.event.addListener(newMarker, 'spider_click', () => {
            app.markerDialog = true
            app.selectedIssueForDisplay = issue
          })
          this.markerSpiderfier.trackMarker(newMarker, () => {})
        }
        if (options.showLabel) {
          let infowindow = new google.maps.InfoWindow({
            content: `<h3 style="color: black;">${issue.area}, ${
              issue.issue_type
            } - ${dfnFormat(
              dfnParseISO(issue.inspection_date),
              'MMMM d, yyyy'
            )}</h3>`
          })

          newMarker.addListener('mouseover', () => {
            if (app.map) {
              infowindow.open(app.map, newMarker)
            }
          })
          newMarker.addListener('mouseout', () => {
            infowindow.close()
          })
        }
      }
    },
    drawCulvertMarker(
      point: Point,
      options: drawMarkerOptionsI,
      culvert: CulvertResult,
      inspection?: CulvertInspectionResult
    ): void {
      let app = this
      let newMarker: google.maps.Marker

      if (!inspection) {
        newMarker = app.drawMarker(point, app.disabledIssueMarkerIcon)
      } else {
        let icon =
          inspection.risk_score === null
            ? app.defaultMarkerIcon
            : inspection.risk_score === 0
            ? app.noRiskMarkerIcon
            : inspection.risk_score < 3
            ? app.lowRiskMarkerIcon
            : inspection.risk_score < 7
            ? app.medRiskMarkerIcon
            : app.highRiskMarkerIcon
        newMarker = app.drawMarker(point, icon)
      }

      app.issueMarkerList.push(newMarker)
      if (options.clickable && this.markerSpiderfier) {
        google.maps.event.addListener(newMarker, 'spider_click', () => {
          app.culvertMarkerDialog = true
          app.selectedCulvertForDisplay = culvert
          app.selectedInspectionForDisplay =
            inspection !== undefined ? inspection : null
        })
        this.markerSpiderfier.trackMarker(newMarker, () => {})
      }
      if (options.showLabel) {
        let infowindow = new google.maps.InfoWindow({
          content: `<h3 style="color: black;">
          ${culvert.site_culvert_id} ${
            inspection
              ? `- ${dfnFormat(
                  dfnParseISO(inspection.inspection_date),
                  'MMMM d, yyyy HH:mm'
                )}`
              : ''
          }</h3>`
        })

        newMarker.addListener('mouseover', () => {
          if (app.map) {
            infowindow.open(app.map, newMarker)
          }
        })
        newMarker.addListener('mouseout', () => {
          infowindow.close()
        })
      }
    },
    markerCluster() {
      if (this.map) {
        let markerClusterOptions = {
          styles: [
            {
              anchorText: [20, 0],
              height: 53,
              url: require('../../static/map/cluster1.png'),
              width: 53
            },
            {
              anchorText: [20, 0],

              height: 56,
              url: require('../../static/map/cluster2.png'),
              width: 56
            },
            {
              anchorText: [20, 0],
              height: 66,
              url: require('../../static/map/cluster3.png'),
              width: 66
            },
            {
              anchorText: [20, 0],
              height: 78,
              url: require('../../static/map/cluster4.png'),
              width: 78
            },
            {
              anchorText: [20, 0],
              height: 90,
              url: require('../../static/map/cluster5.png'),
              width: 90
            }
          ],
          maxZoom: 18
        }
        this.markerClusterObj = new MarkerClusterer(
          this.map,
          this.issueMarkerList,
          markerClusterOptions as any
        )
      }
    },
    drawMarker(
      point: Point,
      icon: google.maps.Icon | google.maps.Symbol
    ): google.maps.Marker {
      let app = this
      let pos: google.maps.LatLngLiteral = {
        lat: point.coordinates[1],
        lng: point.coordinates[0]
      } //coordinates [long, lat] always

      let marker: google.maps.Marker = new google.maps.Marker({
        position: pos,
        icon
      })

      marker.setMap(app.map)
      return marker
    },
    drawUserLocation(zoomOnUser: boolean): void {
      let app = this
      if (app.userMarker !== null) {
        app.userMarker.setMap(null)
        app.userMarker = null
      }
      if (
        app.map !== null &&
        app.currentPosition &&
        app.currentPositionAsPoint &&
        app.currentPositionAsGoogle
      ) {
        let icon = app.noHeadingUserMarkerIcon
        if (
          app.currentPosition.coords.heading !== null &&
          app.currentPosition.coords.heading >= 0
        ) {
          app.userMarkerIcon.rotation = app.currentPosition.coords.heading
          icon = app.userMarkerIcon
        }

        app.userMarker = app.drawMarker(app.currentPositionAsPoint, icon)
        if (app.map !== null && zoomOnUser) {
          app.map.setZoom(15)
          app.map.setCenter(app.currentPositionAsGoogle)
        }
        app.userMarkerOnMap = true
      }
    },
    removeAllMarkers(): void {
      let app = this
      if (app.issueMarkerList.length > 0) {
        for (let i = 0; i < app.issueMarkerList.length; i++) {
          app.issueMarkerList[i].setMap(null)
        }
        app.issueMarkerList = []
      }
      if (app.markerClusterObj !== null) {
        app.markerClusterObj.clearMarkers()
      }
      if (app.issueList.length > 0) {
        app.issueList = []
      }
      if (app.userMarker !== null) {
        app.userMarker.setMap(null)
      }
      if (app.mapClickedMarker !== null) {
        app.mapClickedMarker.setMap(null)
      }
    },
    zoomMapAtPoint(lat: number, long: number, zoom: number): void {
      let app = this

      if (app.map !== null) {
        let pos: google.maps.LatLngLiteral = {
          lat: lat,
          lng: long
        }
        app.map.setZoom(zoom)
        app.map.setCenter(pos)
      }
    },
    zoomMapOnUser(): void {
      let app = this
      if (app.currentPosition !== null) {
        app.zoomMapAtPoint(
          app.currentPosition.coords.latitude,
          app.currentPosition.coords.longitude,
          17
        )
        this.selectedSubAsset = null
      }
    },
    zoomMapOnAsset(): void {
      let app = this
      if (app.$typedStore.state.position && app.$typedStore.state.zoom) {
        app.zoomMapAtPoint(
          app.$typedStore.state.position.lat,
          app.$typedStore.state.position.lng,
          app.$typedStore.state.zoom
        )
      } else if (
        app.$typedStore.state.position &&
        !app.$typedStore.state.zoom
      ) {
        app.zoomMapAtPoint(
          app.$typedStore.state.position.lat,
          app.$typedStore.state.position.lng,
          13
        )
      } else if (
        !app.$typedStore.state.position &&
        app.$typedStore.state.zoom &&
        app.$typedStore.getters.selectedAsset
      ) {
        app.zoomMapAtPoint(
          app.$typedStore.getters.selectedAsset.latitude,
          app.$typedStore.getters.selectedAsset.longitude,
          app.$typedStore.state.zoom
        )
      } else if (this.$typedStore.getters.selectedAsset) {
        this.zoomMapAtPoint(
          this.$typedStore.getters.selectedAsset.latitude,
          this.$typedStore.getters.selectedAsset.longitude,
          13
        )
      }
    },
    zoomMapOnArea(area: Polygon, zoomLevel?: number): void {
      let app = this
      let polygonCenter: Feature<Point> = centerOfMass(area)

      if (polygonCenter.geometry !== null && !zoomLevel) {
        app.zoomMapAtPoint(
          polygonCenter.geometry.coordinates[1],
          polygonCenter.geometry.coordinates[0],
          15
        )
      } else if (polygonCenter.geometry !== null && zoomLevel) {
        app.zoomMapAtPoint(
          polygonCenter.geometry.coordinates[1],
          polygonCenter.geometry.coordinates[0],
          zoomLevel
        )
      }
    },
    zoomMapOnMidPoint(point1: Point, point2: Point, zoomLevel?: number): void {
      let mid: Feature<Point> = midpoint(point1, point2)

      if (mid.geometry !== null && !zoomLevel) {
        this.zoomMapAtPoint(
          mid.geometry.coordinates[1],
          mid.geometry.coordinates[0],
          13
        )
      } else if (mid.geometry !== null && zoomLevel) {
        this.zoomMapAtPoint(
          mid.geometry.coordinates[1],
          mid.geometry.coordinates[0],
          zoomLevel
        )
      }
    },
    backToDefaultZoomedPoint() {
      let app = this
      if (app.$typedStore.getters.selectedAsset && !app.selectedSubAsset) {
        app.zoomMapAtPoint(
          app.$typedStore.getters.selectedAsset.latitude,
          app.$typedStore.getters.selectedAsset.longitude,
          13
        )
      } else if (
        app.$typedStore.getters.selectedAsset &&
        app.selectedSubAsset
      ) {
        app.zoomMapAtPoint(
          app.selectedSubAsset.latitude,
          app.selectedSubAsset.longitude,
          13
        )
      }
      app.$typedStore.commit('setZoom', null)
      app.$typedStore.commit('setPosition', null)
      app.zoomPositionChanged = false
    },
    riskScoreColor(riskScore: number | null, isInteractive: boolean): string {
      return riskToColour(riskScore, isInteractive)
    },
    linkToMaps(): void {
      if (
        this.selectedIssueForDisplay &&
        this.selectedIssueForDisplay.gps_point
      ) {
        let point = this.selectedIssueForDisplay.gps_point as Point
        let link = ''
        if (IsAppleMobileDevice()) {
          link = `http://maps.apple.com/?daddr=${point.coordinates[1]},${point.coordinates[0]}`
        } else {
          link = `https://www.google.com/maps/dir/?api=1&destination=${point.coordinates[1]},${point.coordinates[0]}`
        }
        window.open(link)
      }
    }
  },
  mounted() {
    try {
      this.initializeMap()
    } catch (error) {
      this.$emit('mapLoaded', false)
    }
  }
})

interface MapComponentI {
  defaultPosition: { lat: number; lng: number }
  map: google.maps.Map | null
  markerSpiderfier: OverlappingMarkerSpiderfier | null
  markerClusterObj: MarkerClusterer | null
  issueList: VegetationIssueResult[]
  boundsChanged: boolean
  zoom?: number
  disabledIssueMarkerIcon: google.maps.Icon
  defaultMarkerIcon: google.maps.Icon
  noRiskMarkerIcon: google.maps.Icon
  lowRiskMarkerIcon: google.maps.Icon
  medRiskMarkerIcon: google.maps.Icon
  highRiskMarkerIcon: google.maps.Icon
  userMarkerIcon: google.maps.Symbol
  noHeadingUserMarkerIcon: google.maps.Symbol
  clickedMapMarkerIcon: google.maps.Icon
  polygonList: google.maps.Polygon[]
  polygonLabels: google.maps.Marker[]
  linePath: google.maps.Polyline | null
  issueMarkerList: google.maps.Marker[]
  userMarker: google.maps.Marker | null
  mapClickedMarker: google.maps.Marker | null
  manuallyDrawnPath: google.maps.Polygon | null
  hideMarkers: boolean
  areaDialog: boolean
  selectedAreaForDisplay: ManagementAreaResult | null
  markerDialog: boolean
  culvertMarkerDialog: boolean
  selectedIssueForDisplay: VegetationIssueResult | null
  selectedCulvertForDisplay: CulvertResult | null
  selectedInspectionForDisplay: CulvertInspectionResult | null
  drawingManager: google.maps.drawing.DrawingManager | null

  userMarkerOnMap: boolean
  selectedSubAsset: SubAssetResult | null
  zoomPositionChanged: boolean

  selectedYear: number | null
  uniqueYearList: number[]
  yearSelectMenu: boolean
}

interface drawMarkerOptionsI {
  clickable: boolean
  culvertTextTitle: string
  showLabel: boolean
}
