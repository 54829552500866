


















































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import {
  VegetationIssueResult,
  SubAssetResult,
  ManagementAreaResult,
  IssueActionsRes,
  RelationsRes,
  SpeciesRes,
  GetThirdPartyCompanyResponse
} from '../veg-common/apiTypes'
import { UserObject } from '../veg-common/apiTypes/newResultTypes'
import { IsMobileDevice } from '../util/ParseUserAgent'

import { isSameDay, isAfter, isBefore } from 'date-fns'

export default Vue.extend({
  props: {
    vegeIssueData: {
      type: Array as () => VegetationIssueResult[],
      default: (): VegetationIssueResult[] => []
    },
    relations: {
      type: Object as () => RelationsRes,
      default: () => {}
    },
    subAssetData: {
      type: Array as () => SubAssetResult[],
      default: (): SubAssetResult[] => []
    },
    managementAreas: {
      type: Array as () => ManagementAreaResult[],
      default: (): ManagementAreaResult[] => []
    }
  },
  data(): VegeIssueFilterI {
    return {
      // default to closed for expansion panel. Only applicable on mobile
      filterExpansionPanel: false,
      wrapped: true,
      badgeContent: 0,

      managementAreaSelectedValues: [],

      dates: [],
      menu: false,

      interactiveOptions: [
        { text: 'Show Non-Interactive Issues', value: 0 },
        { text: 'Show Interactive Issues', value: 1 }
      ],
      interactiveSelectedValue: null,

      riskScoreOptions: [
        { text: 'No Risk', value: 0 },
        { text: 'Low Risk', value: 1 },
        { text: 'Medium Risk', value: 5 },
        { text: 'High Risk', value: 10 }
      ],
      riskScoreSelectedValues: [],

      photoOptions: [
        { text: 'No Photos', value: 0 },
        { text: 'Contains Photos', value: 1 }
      ],
      photoStatusSelectedValues: [],
      issueTypeSelectedValues: [],
      actionTakenSelectedValues: [],
      physiologicalStageSelectedValues: [],
      infraVegetationTypesSelectedValues: [],
      distributionSelectedValues: [],
      proximitySelectedValues: [],
      subAssetSelectedValues: [],

      originalIssueOptions: [
        { text: 'Is Original Issue', value: 'Original Issue' },
        { text: 'Not Original Issue', value: 'Not Original Issue' }
      ],
      originalIssueSelectedValues: [],

      selectedInitiators: [],
      selectedAssignedUsers: [],
      selectedServiceCompanies: [],

      selectedWeedSpecies: [],
      selectedActionsRequired: [],

      issueIdSearch: null,

      areaSizeMin: 0,
      areaSizeMax: 100,
      areaSizeRange: [0, 100],

      individualCountMin: 0,
      individualCountMax: 100,
      individualCountRange: [0, 100],

      commentSearch: '',

      downloadDialog: false,
      deleteIssuesDialog: false,
      filteredDataForTable: []
    }
  },
  computed: {
    dataForTable(): VegetationIssueResult[] {
      if (!this.vegeIssueData) {
        return []
      }
      return this.vegeIssueData.filter((item: VegetationIssueResult) => {
        const areaNameFilterMatches = this.filterSelectionHelper(
          this.managementAreaSelectedValues,
          item,
          'area'
        )
        const riskScoreFilterMatches = this.filterSelectionHelper(
          this.riskScoreSelectedValues,
          item,
          'risk_score'
        )
        const issueTypeFilterMatches = this.filterSelectionHelper(
          this.issueTypeSelectedValues,
          item,
          'issue_type'
        )
        const dateRangeFilterMatches = this.dateRangeFilter(
          item.inspection_date
        )
        const photoFilterMatches = this.photoFilter(item)
        const originalIssueMatches = this.originalIssueFilter(item)
        const distributionMatches = this.filterSelectionHelper(
          this.distributionSelectedValues,
          item,
          'distribution'
        )
        const proximitiesMatches = this.filterSelectionHelper(
          this.proximitySelectedValues,
          item,
          'proximity'
        )
        const actionTakenMatches = this.filterSelectionHelper(
          this.actionTakenSelectedValues,
          item,
          'action_taken'
        )
        const physiologicalStageMatches = !this.physiologicalStageSelectedValues
          ? true
          : this.filterSelectionHelper(
              this.physiologicalStageSelectedValues,
              item,
              'physiological_stage'
            )
        const infraVegetationMatches = this.filterSelectionHelper(
          this.infraVegetationTypesSelectedValues,
          item,
          'vegetation_type'
        )
        const initiatorMatches =
          this.selectedInitiators.length > 0 ? this.initiatorFilter(item) : true

        const areaSizeMatches =
          this.areaSizeRange[0] !== 0 || this.areaSizeRange[1] !== 100
            ? this.sliderSelection(this.areaSizeRange, item, 'area_size')
            : true

        const individualCountMatches =
          this.individualCountRange[0] !== 0 ||
          this.individualCountRange[1] !== 100
            ? this.sliderSelection(
                this.individualCountRange,
                item,
                'individual_count'
              )
            : true

        const weedSpeciesMatches =
          this.selectedWeedSpecies.length > 0
            ? this.weedSpeciesFilter(item)
            : true

        const actionsRequiredMatches =
          this.selectedActionsRequired.length > 0
            ? this.actionsRequiredFilter(item)
            : true

        const subAssetMatches =
          this.subAssetSelectedValues.length > 0
            ? this.subAssetFilter(item)
            : true

        const issueIdSearchMatches = this.issueIdFilter(item)

        const commentsSearchMatches = this.commentFilter(item.comments)

        const interactiveIssueMatches = this.interactiveIssueFilter(
          item.isInteractive
        )

        const assignedIssueMatches =
          this.selectedAssignedUsers.length > 0
            ? this.assignedIssueFilter(item)
            : true

        const assignedServiceCompanyMatches =
          this.selectedServiceCompanies.length > 0
            ? this.serviceCompanyFilter(item)
            : true

        return (
          areaNameFilterMatches &&
          riskScoreFilterMatches &&
          issueTypeFilterMatches &&
          dateRangeFilterMatches &&
          photoFilterMatches &&
          originalIssueMatches &&
          distributionMatches &&
          proximitiesMatches &&
          actionTakenMatches &&
          physiologicalStageMatches &&
          infraVegetationMatches &&
          initiatorMatches &&
          areaSizeMatches &&
          individualCountMatches &&
          weedSpeciesMatches &&
          actionsRequiredMatches &&
          issueIdSearchMatches &&
          subAssetMatches &&
          commentsSearchMatches &&
          interactiveIssueMatches &&
          assignedIssueMatches &&
          assignedServiceCompanyMatches
        )
      })
    },
    isMobileDevice(): boolean {
      return IsMobileDevice()
    },
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    clearAllFiltersClass(): string {
      if (this.isSmallScreen) {
        return 'pa-0 ma-0'
      } else {
        return 'd-flex justify-end pa-0 ma-0'
      }
    },
    applyFilterButtonClass(): string {
      if (this.isSmallScreen) {
        return 'flex-grow-0 primary accentAjmBlue--text'
      } else {
        return 'flex-grow-0 primary accentAjmBlue--text pr-6 pl-6 mr-10'
      }
    },
    dateRangeText(): string {
      return this.dates.join(' ~ ')
    },
    managementAreaOptions(): selectOptions[] | [] {
      if (this.managementAreas) {
        return this.generateSelectOptions(this.managementAreas, 'name')
      } else {
        return []
      }
    },
    issueTypeOptions(): selectOptions[] | [] {
      if (this.relations) {
        return this.generateSelectOptions(
          this.relations.issueTypes,
          'issue_type'
        )
      } else {
        return []
      }
    },
    actionTakenOptions(): selectOptions[] | [] {
      if (this.relations) {
        return this.generateSelectOptions(
          this.relations.takenActions,
          'issue_action'
        )
      } else {
        return []
      }
    },
    actionRequiredOptions(): IssueActionsRes[] | [] {
      let actionsRequired = []
      if (this.relations) {
        for (let data of this.relations.requiredActions) {
          actionsRequired.push(data)
        }
      }
      return actionsRequired
    },
    physiologicalStageOptions(): selectOptions[] | [] {
      if (this.relations) {
        return this.generateSelectOptions(
          this.relations.physiologicalStages,
          'physiological_stage'
        )
      } else {
        return []
      }
    },
    infraVegetationTypesOptions(): selectOptions[] {
      if (this.relations) {
        return this.generateSelectOptions(
          this.relations.infraVegetationTypes,
          'vegetation_type'
        )
      } else {
        return []
      }
    },
    distributionOptions(): selectOptions[] {
      if (this.relations) {
        return this.generateSelectOptions(
          this.relations.distributions,
          'distribution'
        )
      } else {
        return []
      }
    },
    proximityOptions(): selectOptions[] {
      if (this.relations) {
        return this.generateSelectOptions(
          this.relations.proximities,
          'proximity'
        )
      } else {
        return []
      }
    },
    multipleInitiatorSelectOptions(): UserObject[] {
      return this.$typedStore.getters.usersArray
    },
    serviceCompanyOptions(): GetThirdPartyCompanyResponse[] {
      let companies: GetThirdPartyCompanyResponse[] = []
      for (let tpc of this.$typedStore.getters.relatedThirdPartyCompanies) {
        companies.push(tpc.company)
      }
      return companies
    },
    selectableSpeciesSelectOptions(): SpeciesRes[] {
      let species = []
      if (this.relations) {
        for (let data of this.relations.species) {
          species.push(data)
        }
      }
      return species
    },
    isClientUser(): boolean {
      if (this.$typedStore.getters.user) {
        return this.$typedStore.getters.user.userRoleId === 1
      }
      return false
    },
    badgeValue(): boolean {
      return this.badgeContent ? true : false
    }
  },
  methods: {
    applyFilters(): void {
      this.$emit('setIssueData', this.dataForTable)
      this.setBadgeContent()

      this.$typedStore.dispatch('setAppliedFilters', {
        managementAreaSelectedValues: this.managementAreaSelectedValues,
        dates: this.dates,
        riskScoreSelectedValues: this.riskScoreSelectedValues,
        photoStatusSelectedValues: this.photoStatusSelectedValues,
        issueTypeSelectedValues: this.issueTypeSelectedValues,
        actionTakenSelectedValues: this.actionTakenSelectedValues,
        physiologicalStageSelectedValues: this.physiologicalStageSelectedValues,
        infraVegetationTypesSelectedValues:
          this.infraVegetationTypesSelectedValues,
        distributionSelectedValues: this.distributionSelectedValues,
        proximitySelectedValues: this.proximitySelectedValues,
        originalIssueSelectedValues: this.originalIssueSelectedValues,
        selectedInitiators: this.selectedInitiators,
        selectedWeedSpecies: this.selectedWeedSpecies,
        selectedActionsRequired: this.selectedActionsRequired,
        issueIdSearch: this.issueIdSearch,
        subAssetSelectedValue: this.subAssetSelectedValues,
        commentSearch: this.commentSearch,
        selectedAssignedUsers: this.selectedAssignedUsers,
        selectedServiceCompanies: this.selectedServiceCompanies,
        interactiveSelectedValue: this.interactiveSelectedValue
      })
    },
    setBadgeContent(): void {
      let filtersApplied = 0
      this.managementAreaSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.dates.length > 0 ? filtersApplied++ : filtersApplied + 0
      this.riskScoreSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.photoStatusSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.issueTypeSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.actionTakenSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.physiologicalStageSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.infraVegetationTypesSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.distributionSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.proximitySelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.originalIssueSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.selectedInitiators.length > 0 ? filtersApplied++ : filtersApplied + 0
      this.selectedWeedSpecies.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.selectedActionsRequired.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.issueIdSearch ? filtersApplied++ : filtersApplied + 0
      this.commentSearch.length > 0 ? filtersApplied++ : filtersApplied + 0
      this.subAssetSelectedValues.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.selectedAssignedUsers.length > 0
        ? filtersApplied++
        : filtersApplied + 0
      this.interactiveSelectedValue ? filtersApplied++ : filtersApplied + 0
      this.badgeContent = filtersApplied
    },
    clearFilters(): void {
      this.originalIssueSelectedValues = []
      this.managementAreaSelectedValues = []
      this.dates = []
      this.riskScoreSelectedValues = []
      this.photoStatusSelectedValues = []
      this.issueTypeSelectedValues = []
      this.actionTakenSelectedValues = []
      this.physiologicalStageSelectedValues = []
      this.infraVegetationTypesSelectedValues = []
      this.distributionSelectedValues = []
      this.proximitySelectedValues = []
      this.selectedInitiators = []
      this.selectedAssignedUsers = []
      this.selectedServiceCompanies = []
      this.selectedWeedSpecies = []
      this.selectedActionsRequired = []
      this.issueIdSearch = null
      this.subAssetSelectedValues = []
      this.commentSearch = ''
      this.interactiveSelectedValue = null
      this.applyFilters()
      // To clear the url when searching for Original Issue or Follow-Up Issues from IssueInformationCard
      if (
        this.$route.name === 'Vegetation Issues' &&
        this.$route.query.issueId
      ) {
        this.$router.replace({
          name: 'Vegetation Issues',
          query: {}
        })
      }
    },
    photoFilter(item: VegetationIssueResult): boolean {
      if (this.photoStatusSelectedValues.length <= 0) return true

      for (let selected of this.photoStatusSelectedValues) {
        if (
          (selected === 0 && item.photos.length === 0) ||
          (selected === 1 && item.photos.length > 0)
        ) {
          return true
        }
      }
      return false
    },
    originalIssueFilter(item: VegetationIssueResult): boolean {
      if (this.originalIssueSelectedValues.length <= 0) return true

      for (let selected of this.originalIssueSelectedValues) {
        if (
          !(
            (selected === 'Original Issue' && item.original_issue_id) ||
            (selected === 'Not Original Issue' && !item.original_issue_id)
          )
        ) {
          return true
        }
      }

      return false
    },
    initiatorFilter(item: VegetationIssueResult): boolean {
      //user is stored as 'firstName lastName' in result, need to match on that
      for (let user of this.selectedInitiators) {
        if (user.first_name + ' ' + user.last_name === item.user) {
          return true
        }
      }
      return false
    },
    assignedIssueFilter(item: VegetationIssueResult): boolean {
      for (let user of this.selectedAssignedUsers) {
        //Users send their assignedIssues in the backend, so while assignedIssues does not exist on UserObject (issues with when we declare the schema variables and when we use them)
        //a user object will have assignedIssues. The honest fix for typing this ends up being removing the schemas as they clearly are not actually working the way we want them to.
        //@ts-ignore
        if (user.assignedIssues && user.assignedIssues.length > 0) {
          //@ts-ignore
          for (let issue of user.assignedIssues) {
            if (issue.id === item.id || item.original_issue_id === issue.id)
              return true
          }
        }
      }

      return false
    },
    serviceCompanyFilter(item: VegetationIssueResult): boolean {
      for (let company of this.selectedServiceCompanies) {
        for (let issue of company.vegetationIssues) {
          if (issue.id === item.id || item.original_issue_id === issue.id)
            return true
        }
      }

      return false
    },
    weedSpeciesFilter(item: VegetationIssueResult): boolean {
      let containSpeciesSelected = false
      if (item.species && item.species.length > 0) {
        for (let i = 0; i < this.selectedWeedSpecies.length; i++) {
          for (let j = 0; j < item.species.length; j++) {
            if (this.selectedWeedSpecies[i].species === item.species[j]) {
              containSpeciesSelected = true
            }
          }
        }
      }
      return containSpeciesSelected
    },

    actionsRequiredFilter(item: VegetationIssueResult): boolean {
      let containsAction = false
      if (item.actionsRequired && item.actionsRequired.length > 0) {
        for (let i = 0; i < this.selectedActionsRequired.length; i++) {
          for (let j = 0; j < item.actionsRequired.length; j++) {
            if (
              this.selectedActionsRequired[i].issue_action ===
              item.actionsRequired[j]
            ) {
              containsAction = true
            }
          }
        }
      }
      return containsAction
    },

    subAssetFilter(item: VegetationIssueResult): boolean {
      let containsSubAsset = false
      if (item.area && this.subAssetSelectedValues.length > 0) {
        let foundArea = this.managementAreas.find(
          (area: ManagementAreaResult) => {
            return area.name === item.area
          }
        )
        if (foundArea) {
          for (let i = 0; i < this.subAssetSelectedValues.length; i++) {
            if (foundArea.subAssetId === this.subAssetSelectedValues[i].id) {
              containsSubAsset = true
            }
          }
        }
      }
      return containsSubAsset
    },

    sliderSelection(
      selectedValue: number[],
      item: VegetationIssueResult,
      key: keyof VegetationIssueResult
    ): boolean {
      let isSelected
      if (
        item[key] !== null &&
        item[key]! >= selectedValue[0] &&
        item[key]! <= selectedValue[1]
      ) {
        isSelected = true
      } else {
        isSelected = false
      }
      return isSelected
    },

    //optionType array always has item as string
    generateSelectOptions(optionType: any[], item: string): selectOptions[] {
      let option = []
      optionType = optionType.sort((a, b) =>
        a[item].localeCompare(b[item], undefined, { numeric: true })
      )
      for (let data of optionType) {
        option.push({ text: data[item], value: data[item] })
      }
      return option
    },
    issueIdFilter(item: VegetationIssueResult): boolean {
      if (
        !this.issueIdSearch ||
        this.issueIdSearch.toString().replace(/\s/g, '').length === 0
      ) {
        return true
      }
      return item.original_issue_id
        ? item.original_issue_id ===
            Number(this.issueIdSearch.toString().replace(/\s/g, ''))
        : item.id === Number(this.issueIdSearch.toString().replace(/\s/g, ''))
    },
    commentFilter(value: string): boolean {
      if (
        !this.commentSearch ||
        this.commentSearch.replace(/\s/g, '').length === 0
      ) {
        return true
      }
      return value
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(this.commentSearch.toLowerCase().replace(/\s/g, ''))
    },
    interactiveIssueFilter(item: boolean): boolean {
      let isInteractiveIssue = false
      if (this.interactiveSelectedValue === null) isInteractiveIssue = true
      if (this.interactiveSelectedValue === 0 && item === false) {
        isInteractiveIssue = true
      } else if (this.interactiveSelectedValue === 1 && item === true) {
        isInteractiveIssue = true
      }
      return isInteractiveIssue
    },
    convertDateRangeData(date: string): Date {
      let dateArray = date.split('-')
      let year = parseInt(dateArray[0], 10)
      let month = parseInt(dateArray[1], 10) - 1
      let day = parseInt(dateArray[2], 10)
      return new Date(year, month, day)
    },
    dateRangeFilter(value: string): boolean {
      if (this.dates.length === 0) {
        return true
      }
      let _entryDate1 = this.convertDateRangeData(this.dates[0])
      let filteredDate = new Date(value)
      if (!this.dates[1]) {
        return (
          isSameDay(filteredDate, _entryDate1) ||
          isAfter(filteredDate, _entryDate1)
        )
      } else {
        let _entryDate2 = this.convertDateRangeData(this.dates[1])
        if (
          isSameDay(filteredDate, _entryDate1) ||
          isSameDay(filteredDate, _entryDate2)
        ) {
          return true
        } else if (isBefore(_entryDate1, _entryDate2)) {
          return (
            isAfter(filteredDate, _entryDate1) &&
            isBefore(filteredDate, _entryDate2)
          )
        } else {
          return (
            isBefore(filteredDate, _entryDate1) &&
            isAfter(filteredDate, _entryDate2)
          )
        }
      }
    },
    removeSelectedInitiators(toRemove: UserObject): void {
      if (!this.selectedInitiators) return

      let index = this.selectedInitiators.findIndex((initiator: UserObject) => {
        return initiator === toRemove
      })
      if (index >= 0) this.selectedInitiators.splice(index, 1)
    },
    removeSelectedAssignedUsers(toRemove: string): void {
      if (!this.selectedAssignedUsers) return
      let index = this.selectedAssignedUsers.findIndex(
        (initiator: UserObject) => {
          return initiator === toRemove
        }
      )
      if (index >= 0) this.selectedAssignedUsers.splice(index, 1)
    },
    removeSpecies(toRemove: SpeciesRes): void {
      let index = this.selectedWeedSpecies.findIndex((species: SpeciesRes) => {
        return species.id === toRemove.id
      })
      if (index >= 0) this.selectedWeedSpecies.splice(index, 1)
    },
    removeSelectedSubAsset(toRemove: SubAssetResult): void {
      let index = this.subAssetSelectedValues.findIndex(
        (subAsset: SubAssetResult) => {
          return subAsset.id === toRemove.id
        }
      )
      if (index >= 0) this.subAssetSelectedValues.splice(index, 1)
    },
    removeRequiredAction(toRemove: IssueActionsRes): void {
      let index = this.selectedActionsRequired.findIndex(
        (action: IssueActionsRes) => {
          return action.id === toRemove.id
        }
      )
      if (index >= 0) this.selectedActionsRequired.splice(index, 1)
    },
    removeSelectedFromList(
      toRemove: string | number,
      list: string[] | number[]
    ): void {
      if (list.length <= 0) return
      let index = list.findIndex((item: string | number) => {
        return item === toRemove
      })

      if (index >= 0) list.splice(index, 1)
    },
    filterSelectionHelper(
      selectedValue: string[] | number[],
      item: VegetationIssueResult,
      key: keyof VegetationIssueResult
    ): boolean {
      if (selectedValue.length <= 0) return true

      let filtered = false
      for (let value of selectedValue) {
        if (item[key] === value) {
          return true
        }
      }
      return filtered
    }
  },
  watch: {
    vegeIssueData(): void {
      //data has changed, new issue added, removed or synced, reflect that in the table data
      this.filteredDataForTable = this.dataForTable
    }
  },
  created() {
    if (
      this.$route.query.issueId &&
      !this.$route.query.original &&
      typeof this.$route.query.issueId === 'string' &&
      Number(this.$route.query.issueId) > 0
    ) {
      this.issueIdSearch = this.$route.query.issueId
      this.originalIssueSelectedValues = ['Not Original Issue']
    } else if (
      this.$route.query.issueId &&
      this.$route.query.original &&
      typeof this.$route.query.issueId === 'string' &&
      Number(this.$route.query.issueId) > 0
    ) {
      this.issueIdSearch = this.$route.query.issueId
      this.originalIssueSelectedValues = ['Original Issue']
    } else {
      if (
        this.$route !== undefined &&
        typeof this.$route.query.areaName === 'string'
      ) {
        this.managementAreaSelectedValues = [this.$route.query.areaName]
      } else {
        this.managementAreaSelectedValues =
          this.$typedStore.state.appliedFilters.managementAreaSelectedValues
      }

      if (this.$route.query.risk) {
        try {
          this.riskScoreSelectedValues = [Number(this.$route.query.risk)]
        } catch (e) {
          this.riskScoreSelectedValues =
            this.$typedStore.state.appliedFilters.riskScoreSelectedValues
        }
      } else {
        this.riskScoreSelectedValues =
          this.$typedStore.state.appliedFilters.riskScoreSelectedValues
      }

      if (this.$route.query.actionsRequired) {
        if (this.$route.query.actionsRequired === 'true') {
          this.selectedActionsRequired = this.actionRequiredOptions
        } else {
          this.selectedActionsRequired = []
        }
      } else {
        this.selectedActionsRequired =
          this.$typedStore.state.appliedFilters.selectedActionsRequired
      }
    }

    this.originalIssueSelectedValues =
      this.$typedStore.state.appliedFilters.originalIssueSelectedValues
    this.dates = this.$typedStore.state.appliedFilters.dates
    this.photoStatusSelectedValues =
      this.$typedStore.state.appliedFilters.photoStatusSelectedValues
    this.issueTypeSelectedValues =
      this.$typedStore.state.appliedFilters.issueTypeSelectedValues
    this.actionTakenSelectedValues =
      this.$typedStore.state.appliedFilters.actionTakenSelectedValues
    this.physiologicalStageSelectedValues =
      this.$typedStore.state.appliedFilters.physiologicalStageSelectedValues
    this.infraVegetationTypesSelectedValues =
      this.$typedStore.state.appliedFilters.infraVegetationTypesSelectedValues
    this.distributionSelectedValues =
      this.$typedStore.state.appliedFilters.distributionSelectedValues
    this.proximitySelectedValues =
      this.$typedStore.state.appliedFilters.proximitySelectedValues
    this.selectedInitiators = this.$typedStore.state.appliedFilters
      .selectedInitiators as UserObject[]
    this.selectedWeedSpecies =
      this.$typedStore.state.appliedFilters.selectedWeedSpecies
    this.issueIdSearch = this.$typedStore.state.appliedFilters.issueIdSearch
      ? this.$typedStore.state.appliedFilters.issueIdSearch.toString()
      : null
    this.subAssetSelectedValues =
      this.$typedStore.state.appliedFilters.subAssetSelectedValues
    this.commentSearch = this.$typedStore.state.appliedFilters.commentSearch
    this.selectedAssignedUsers =
      this.$typedStore.state.appliedFilters.selectedAssignedUsers
    //can't use a proper schema due to a potential circular reference that the validator seems to think could happen
    //Shechma is saved as just an array, but ts compiler doesn't like that either, so do the cast here to guarantee type and ignore the compiler having issues with it
    //no errors in console when testing
    //@ts-ignore
    this.selectedServiceCompanies = this.$typedStore.state.appliedFilters
      .selectedServiceCompanies as GetThirdPartyCompanyResponse[]
    this.interactiveSelectedValue = this.$typedStore.state.appliedFilters
      .interactiveSelectedValue as number | null

    this.applyFilters()
  }
})

interface VegeIssueFilterI {
  filterExpansionPanel: boolean
  wrapped: boolean
  badgeContent: number
  managementAreaSelectedValues: string[]
  dates: string[]
  menu: boolean
  interactiveOptions: commonOptionsContent[]
  riskScoreOptions: commonOptionsContent[]
  photoOptions: commonOptionsContent[]
  interactiveSelectedValue: null | number
  riskScoreSelectedValues: number[]
  photoStatusSelectedValues: number[]
  issueTypeSelectedValues: string[]
  actionTakenSelectedValues: string[]
  physiologicalStageSelectedValues: string[]
  infraVegetationTypesSelectedValues: string[]
  distributionSelectedValues: string[]
  proximitySelectedValues: string[]
  originalIssueSelectedValues: string[]
  subAssetSelectedValues: SubAssetResult[]
  originalIssueOptions: selectOptions[]
  selectedInitiators: UserObject[]
  selectedAssignedUsers: UserObject[]
  selectedServiceCompanies: GetThirdPartyCompanyResponse[]
  selectedWeedSpecies: SpeciesRes[]
  selectedActionsRequired: IssueActionsRes[]
  issueIdSearch: null | string
  areaSizeMin: number
  areaSizeMax: number
  areaSizeRange: number[]
  individualCountMin: number
  individualCountMax: number
  individualCountRange: number[]
  commentSearch: string
  downloadDialog: boolean
  deleteIssuesDialog: boolean
  filteredDataForTable: VegetationIssueResult[]
}

interface commonOptionsContent {
  text: string
  value: number | null
}
interface selectOptions {
  text: string
  value: string | null
}
