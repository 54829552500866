import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'

import Vue from 'vue'
import * as Entities from '../sqlite/entities'

import { IStore } from './'
import { StateFor } from 'vuex-typescript-interface'

import { CompanyObject } from '../veg-common/apiTypes/newResultTypes'
import { SnackbarParams } from './types'

export default {
  clearAssetData(state: StateFor<IStore>) {
    state.assetData = {
      areas: {},
      inspections: {},
      issues: {},
      subAssets: {},
      assetAssociatedSpecies: {},
      culverts: {},
      culvertInspections: {}
    }
  },
  clearFilters(state: StateFor<IStore>) {
    state.appliedFilters = {
      managementAreaSelectedValues: [],
      dates: [],
      riskScoreSelectedValues: [],
      photoStatusSelectedValues: [],
      issueTypeSelectedValues: [],
      actionTakenSelectedValues: [],
      physiologicalStageSelectedValues: [],
      infraVegetationTypesSelectedValues: [],
      distributionSelectedValues: [],
      proximitySelectedValues: [],
      originalIssueSelectedValues: [],
      selectedInitiators: [],
      selectedWeedSpecies: [],
      selectedActionsRequired: [],
      issueIdSearch: null,
      subAssetSelectedValues: [],
      commentSearch: '',
      selectedAssignedUsers: [],
      selectedServiceCompanies: [],
      interactiveSelectedValue: null
    }
    state.appliedCulvertInspectionFilters = {
      culvertSelectedValues: [],
      inspectionDateRange: [],
      nextInspectionDateRange: [],
      riskScoreSelectedValues: [],
      culvertStatusSelectedValues: [],
      photoStatusSelectedValues: [],
      selectedActionsTaken: [],
      selectedInitiators: [],
      selectedActionsRequired: [],
      commentSearch: ''
    }
    state.appliedCulvertFilters = {
      siteIdSelectedValues: [],
      roadNameSearch: '',
      riskScoreSelectedValues: [],
      culvertStatusSelectedValues: [],
      fishHabitatSelectedValues: [],
      markersSelectedValues: [],
      diameterRange: [-1, 150],
      lengthRange: [-1, 150]
    }
  },
  resetSnackbar(state: StateFor<IStore>) {
    state.snackbarParams = null
  },
  setAppData(
    state: StateFor<IStore>,
    appData: {
      alerts: Entities.AlertResponseEntity[]
      assets: Entities.AssetResultEntity[]
      relatedThirdPartyCompanies: Entities.ThirdPartyEntity[]
      users: Entities.UsersEntity[]
      extraAppData?: Entities.ExtraAppDataEntity
      relations?: Entities.RelationsEntity
      vegIssueFilters?: Entities.VegeIssueAppliedFilters
      culvertInspectionFilters?: Entities.AppliedCulvertInspectionFiltersDataEntity
      culvertFilters?: Entities.AppliedCulvertFiltersDataEntity
      loggedInStoreState?: Entities.AppLoggedInStoreStateEntity
      storeState?: Entities.StoreStateEntity
    }
  ): void {
    appData.assets.forEach((x) => {
      let asset = x.toApiType()
      Vue.set(state.appData.assets, asset.id, asset)
    })
    appData.alerts.forEach((x) => {
      let alert = x.toApiType()
      Vue.set(state.appData.alerts, alert.id, alert)
    })
    appData.users.forEach((x) => {
      let user = x.toApiType()
      Vue.set(state.appData.users, user.id, user)
    })
    appData.relatedThirdPartyCompanies.forEach((x) => {
      let thirdParty = x.toApiType()
      Vue.set(
        state.appData.relatedThirdPartyCompanies,
        thirdParty.company.id,
        thirdParty
      )
    })
    if (appData.relations) {
      state.appData.relations = appData.relations.toApiType()
    }

    if (appData.extraAppData) {
      state.appData.company = JSON.parse(
        appData.extraAppData.company ? appData.extraAppData.company : '{}'
      ) as CompanyObject
      state.appData.selectedAssetId = appData.extraAppData.selectedAssetId
    }
    if (appData.culvertFilters) {
      state.appliedCulvertFilters = appData.culvertFilters.toApiType()
    }
    if (appData.culvertInspectionFilters) {
      state.appliedCulvertInspectionFilters =
        appData.culvertInspectionFilters.toApiType()
    }
    if (appData.vegIssueFilters) {
      state.appliedFilters = appData.vegIssueFilters.toApiType()
    }
    if (appData.loggedInStoreState) {
      state.loggedIntoVegeLogic = appData.loggedInStoreState.loggedIntoVegeLogic
      state.loggedIntoCulvertLogic =
        appData.loggedInStoreState.loggedIntoCulvertLogic
    }
    if (appData.storeState) {
      state.showLsdGrid = appData.storeState.showLsdGrid
    }
  },
  setAssetData(
    state: StateFor<IStore>,
    assetData: {
      areas: Entities.ManagementAreasEntity[]
      issues: Entities.VegetationIssuesResultEntity[]
      subAssets: Entities.SubAssetResultEntity[]
      culverts: Entities.CulvertResultEntity[]
      culvertInspections: Entities.CulvertInspectionResultEntity[]
      assetSpeciesList: Entities.AssetSpeciesEntity[]
    }
  ) {
    assetData.areas.forEach((x) => {
      let area = x.toApiType()
      Vue.set(state.assetData.areas, area.id, area)
    })
    assetData.issues.forEach((x) => {
      let issue = x.toApiType()
      Vue.set(state.assetData.issues, issue.id, issue)
    })
    assetData.subAssets.forEach((x) => {
      let subAsset = x.toApiType()
      Vue.set(state.assetData.subAssets, subAsset.id, subAsset)
    })
    assetData.culverts.forEach(async (x) => {
      let culvert = x.toApiType()
      Vue.set(state.assetData.culverts, culvert.id, culvert)
    })
    assetData.culvertInspections.forEach(async (x) => {
      let culvertInspection = x.toApiType()
      Vue.set(
        state.assetData.culvertInspections,
        culvertInspection.id,
        culvertInspection
      )
    })
    assetData.assetSpeciesList.forEach(async (x) => {
      let species = x.toApiType()
      Vue.set(state.assetData.assetAssociatedSpecies, species.id, species)
    })
  },
  logout(state: StateFor<IStore>) {
    state.loggedIntoVegeLogic = true
    state.loggedIntoCulvertLogic = false
    state.token = null
    state.latestPosition = null
    state.zoom = null
    state.position = null
    state.showMap = true
    state.appData = {
      company: null,
      alerts: {},
      assets: {},
      selectedAssetId: null,
      users: {},
      relatedThirdPartyCompanies: {},
      relations: {
        takenActions: [],
        requiredActions: [],
        distributions: [],
        issueTypes: [],
        physiologicalStages: [],
        proximities: [],
        species: [],
        weedVegetationTypes: [],
        infraVegetationTypes: [],
        culvertStatuses: [],
        culvertTakenActions: [],
        culvertRequiredActions: []
      }
    }
    state.assetData = {
      areas: {},
      inspections: {},
      issues: {},
      subAssets: {},
      assetAssociatedSpecies: {},
      culverts: {},
      culvertInspections: {}
    }
  },
  setLoggedIntoStatus(
    state: StateFor<IStore>,
    loggedInStatuses: {
      loggedIntoVegeLogic: boolean
      loggedIntoCulvertLogic: boolean
    }
  ): void {
    state.loggedIntoVegeLogic = loggedInStatuses.loggedIntoVegeLogic
    state.loggedIntoCulvertLogic = loggedInStatuses.loggedIntoCulvertLogic
  },
  setMapView(state: StateFor<IStore>, showMap?: boolean | null): void {
    if (showMap === undefined) {
      state.showMap = true
    } else {
      state.showMap = showMap
    }
  },
  setPosition(
    state: StateFor<IStore>,
    position: google.maps.LatLngLiteral | null
  ): void {
    if (position) {
      state.position = { lat: position.lat, lng: position.lng }
    } else {
      state.position = position
    }
  },
  setSelectedAssetOverviewYear(state: StateFor<IStore>, year: number | null) {
    state.selectedAssetOverviewYear = year
  },
  setSnackbarParams(state: StateFor<IStore>, snackbarParams: SnackbarParams) {
    state.snackbarParams = snackbarParams
  },
  setStoreState(
    state: StateFor<IStore>,
    storeState: Entities.StoreStateEntity
  ) {
    state.showLsdGrid = storeState.showLsdGrid
  },
  setVersion(state: StateFor<IStore>, version: string) {
    state.version = version
  },
  setZoom(state: StateFor<IStore>, zoom?: number | null) {
    if (zoom === undefined) {
      state.zoom = null
    } else {
      state.zoom = zoom
    }
  }
}
